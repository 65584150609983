<app-header></app-header>
<main class="medium-container py-4 px-xl-5 px-3">
  <div class="card shadow-lg rounded-0 border-0 py-4 py-xl-5">
    <div class="row gy-4 justify-content-center">
      <div class="col-12">
        <h2 class="text-center mb-0">Genere e imprima su Gafete</h2>
      </div>

      <div class="col-lg-10">
        <div class="card border-primary rounded-0">
          <div class="card-body">
            <p class="text-primary mb-0">
              El Gafete se puede imprimir únicamente si usted está registrado al
              <b>Congreso Presencial</b>. Podrá imprimirlo en esta modalidad de
              autoservicio una sola vez.
            </p>
          </div>
        </div>
      </div>

      @switch (step) {
      <!-- form -->
      @case (0) {
      <div class="col-lg-10 px-0 mt-0">
        <form
          class="row gy-4 justify-content-center"
          [formGroup]="data"
          (ngSubmit)="submit()"
        >
          <div class="col-lg-10 col-xl-9 text-center">
            <label for="search" class="form-label mb-4">
              <b> Correo electrónico o nombre de su cuenta</b>
            </label>

            <input
              id="search"
              type="email"
              class="form-control text-center input-amm rounded-pill py-2"
              formControlName="search"
              placeholder="Correo electrónico o nombre"
              [ngClass]="{
                'is-invalid':
                  (data.get('search')?.invalid &&
                    (data.get('search')?.dirty ||
                      data.get('search')?.touched)) ||
                  alertData
              }"
            />

            @if (data.get('search')?.invalid && (data.get('search')?.dirty ||
            data.get('search')?.touched)) {
            <div class="ps-2 mt-2">
              @if (data.get('search')?.errors?.['required']) {
              <span class="text-danger">
                ¡Ingresa el correo electrónico o nombre!
              </span>
              }
            </div>
            }
          </div>

          @if (alertData) {
          <div class="col-lg-10 col-xl-9">
            <p class="text-danger text-center mb-0">
              {{ alertData }}
            </p>
          </div>
          }

          <div class="col-lg-10 col-xl-9">
            <button
              type="submit"
              class="btn btn-primary rounded-pill px-3 px-lg-4 px-xl-5"
              [disabled]="loading"
            >
              SIGUIENTE
              <i class="fa-regular fa-arrow-right ms-1 my-auto"></i>
            </button>
          </div>

          <div class="col-lg-10 col-xl-9">
            <p class="text-primary text-center mb-0">
              Si olvidó el correo con el que está registrado solicítelo en las
              mesas de Soporte.
            </p>
          </div>
        </form>
      </div>
      }
      <!-- LIST -->
      @case (1) { @for (item of profiles; track $index) {
      <div class="col-lg-5">
        <div class="card h-100 rounded-0">
          <div class="card-body h-100 px-0">
            <div class="row py-2 h-100">
              <div class="col-12 mb-3">
                <p><b>Nombre: </b> {{ item.name + " " + item.last_name }}</p>

                <p class="mb-0">
                  <b>Correo: </b>
                  <span class="text-primary">{{ item.email }}</span>
                </p>
              </div>

              <div class="col-12 align-self-end">
                <button
                  type="button"
                  class="btn btn-primary rounded-pill w-100"
                  (click)="confirm($index)"
                  [disabled]="loading"
                >
                  CONFIRMAR
                  <i class="fa-regular fa-arrow-right ms-1 my-auto"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      } }

      <!-- LIST -->

      <!-- form -->
      @case (2) {
      <div class="col-lg-10">
        <p class="mb-0">
          Si sus datos no son los correctos los puede cambiar entrando a su
          cuenta o solicite apoyo en las mesas de Soporte.
        </p>
      </div>

      <div class="col-lg-10 qr-container py-3">
        <!-- congress.image_badge || -->
        <img
          [src]="badge || ('images/badge.jpg' | siteAssets : site)"
          class="badge-image"
          alt="Gafete"
        />

        <div class="qr-content text-center">
          @if (qrCode) {
          <div #image class="qr-code mb-2 mb-lg-4">
            <img [src]="qrCode" alt="Código QR" class="img-fluid w-100" />
          </div>
          }

          <h1 class="fs-1 mb-2 mb-lg-3">
            {{ profile.name + " " + profile.last_name }}
          </h1>

          <h3 class="fs-3 font-normal mb-0">{{ profile.state }}</h3>
        </div>
      </div>

      <div class="col-lg-8 col-xl-6">
        <button
          type="button"
          class="btn btn-primary rounded-pill px-3 px-lg-4 px-xl-5"
          (click)="printQrCode()"
          [disabled]="loading"
        >
          IMPRIMIR @if (loading) {
          <span class="visually-hidden" role="status">Loading...</span>
          } @else {
          <i class="fa-regular fa-arrow-right ms-1 my-auto"></i>
          }
        </button>
      </div>

      <div class="w-100 my-0"></div>
      } }
      <!-- ! RESTART -->

      @if (step > 0) {
      <div class="w-100 my-0"></div>

      <div class="col-lg-8 col-xl-6">
        <button
          type="button"
          class="btn btn-danger rounded-pill px-3 px-lg-4 px-xl-5"
          (click)="restart()"
          [disabled]="loading"
        >
          RECARCAR

          <i class="fa-solid fa-rotate-right ms-1 my-auto"></i>
        </button>
      </div>
      }
    </div>
  </div>
</main>

<app-footer></app-footer>
