import { SiteEnum } from '@enums/Site.enum';

export const environment = {
  analytics: '',
  cookie: 'somos_sys',
  domain: 'somosimss.org.mx',
  waPhone: '',
  key: 'somos',
  login: {
    title: 'SOMOS',
    description: 'Sociedad Oncológica Multidisciplinaria de Seguridad Social',
  },
  association: {
    name: 'SOMOS',
    short_name: 'Sociedad Oncológica Multidisciplinaria de Seguridad Social',
    legal:
      'Sociedad Oncológica Multidisciplinaria de Seguridad Social, SOMOS. 2023',
    congress: '5to Congreso Nacional SOMOS 2023',
  },
  endpoint: 'https://stage.cms.somosimss.org.mx/api/',
  stripeKey:
    'pk_test_51HFucaD8Us1rDW7Yjq0sMyhp0F3qE2CdQC79ghgz0VMaoijkTWuenXMWrWzMiFEQhpeScxfkRxSORWsinSTMjUx900adI5AU0d',
  production: false,
  site: SiteEnum.Somos,
  webcast: 'https://stage.webcast.somosimss.org.mx',
  micuenta: 'https://micuenta.somosimss.org.mx/',
};
